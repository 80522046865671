<template>
  <div id="app" class="container-fluid">
    <!--        <img alt="Vue logo" src="./assets/logo.png">-->
    <!--        <file-upload/>-->

    <div>
      <message-notice ref="messagenotice"/>
      <route-menu ref="routemenu"/>
      <!--        <user-login/>-->

      <!--        <slider-captcha/>-->

      <!-- 路由出口，路由匹配到的组件将渲染在这里 -->
      <router-view/>
    </div>
  </div>
</template>

<script>
// import FileUpload from "./components/FileUpload";
import Server from "./utils/server";
import SeoFooter from "./components/SeoFooter";
// import SliderCaptcha from "./components/SliderCaptcha";
import RouteMenu from "./components/RouteMenu";
import MessageNotice from "./components/MessageNotice";
import Cookies from 'js-cookie'
import $ from 'jquery'


export default {
  name: 'App',
  components: {
    RouteMenu,
    MessageNotice
  },
  created() {  // 模板渲染成html前调用
    this.$root.$refs.App = this;
    this.Server.loadUserInfo(this);
  },
  mounted() {  // 模板渲染成html后调用
    if (['/login', '/login/', '/forgetPassword', '/forgetPassword/'].indexOf(this.$route.path) === -1) {
      this.$root.$refs.RouteMenu.showMe();
    }
  },
  methods: {}
}
</script>

<style lang="scss">

body {
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1.5;
  font-weight: 300;
  /*font-size: 1em;*/
  /*color: #c7ccd8;*/
  /*background-color: #1e2430;*/

  // 禁止网页中文本被选中
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#app {
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hidden {
  display: none;
}

/*BootstrapVue Tooltip*/

.b-tooltip .arrow {
  /*display: none;*/
}

.b-tooltip .tooltip-inner {
  color: #653914;
  background-color: #EBCCAF;
}

.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before {
  border-right-color: #EBCCAF;
}

</style>
